// import $$ from "jquery"; //在需要使用的页面中
// import saveAsa from "file-saver"; //在需要使用的页面中
// const saveAs = saveAsa;
// let $ = $$;
if (typeof jQuery !== "undefined" && typeof saveAs !== "undefined") {
  (function($) {
    $.fn.wordExport = function(fileName) {
      var now = new Date();
      fileName =
        typeof fileName !== "undefined" ? fileName : localStorage.getItem("testName");
      var static1 = {
        mhtml: {
          top:
            "Mime-Version: 1.0\nContent-Base: " +
            location.href +
            '\nContent-Type: Multipart/related; boundary="NEXT.ITEM-BOUNDARY";type="text/html"\n\n--NEXT.ITEM-BOUNDARY\nContent-Type: text/html; charset="utf-8"\nContent-Location: ' +
            location.href +
            "\n\n<!DOCTYPE html>\n<html>\n_html_</html>",
          head:
            '<head>\n<meta http-equiv="Content-Type" content="text/html; charset=utf-8">\n<style>\n_styles_\n</style>\n</head>\n',
          body: "<body>_body_</body>",
        },
      };
      var options = {



        maxWidth: 624,
      };
      // Clone selected element before manipulating it
      var markup = $(this).clone();

      // Remove hidden elements from the output
      markup.each(function() {
        var self = $(this);
        if (self.is(":hidden")) self.remove();
      });

      // Embed all images using Data URLs
      var images = Array();
      var img = markup.find("img");
      // var img = new Image();
      // console.log(img[0]);
      for (let i = 0; i < img.length; i++) {
        img[i].setAttribute("crossOrigin", "Anonymous");
        // img.onload = function() {
        // Calculate dimensions of output image
        const w = Math.min(img[i].width, options.maxWidth);
        const h = img[i].height * (w / img[i].width);
        // console.log(w);
        // console.log(h);
        // Create canvas for converting image to data URL
        const canvas = document.createElement("CANVAS");
        canvas.width = w;
        canvas.height = h;
        // Draw image to canvas
        const context = canvas.getContext("2d");
        // console.log(img[i])
        // return false
        context.drawImage(img[i], 0, 0, w, h);
        // console.log(img[i]);
        // Get data URL encoding of image
        // console.log("canvas--");
        const uri = canvas.toDataURL();
        // console.log(uri);
        // console.log($("#MathJax-Span-1"));
        // var image='';
        // html2canvas($("#MathJax-Span-1"), {
        //   onrendered: function (canvas) {
        //     // console.log(canvas)
        //     var image = canvas.toDataURL("image/png");
        //     // var uri=image
        //     console.log(image);
        //     var pHtml = "<img src=" + image + " />";
        //         $("#MathJax-Span-1").html(pHtml);
        //         console.log($("#MathJax-Span-1"));
        //   },
        // });
        // console.log(image);
        //    var uri =
        //       "";
        $(img[i]).attr("src", img[i].src);
        img[i].width = w * 0.6;
        img[i].height = h * 0.6;
        // Save encoded image to array
        images[i] = {
          type: uri.substring(uri.indexOf(":") + 1, uri.indexOf(";")),
          encoding: uri.substring(uri.indexOf(";") + 1, uri.indexOf(",")),
          location: $(img[i]).attr("src"),
          data: uri.substring(uri.indexOf(",") + 1),
        };
        // };
      }

      // Prepare bottom of mhtml file with image data
      var mhtmlBottom = "\n";
      for (var i = 0; i < images.length; i++) {
        mhtmlBottom += "--NEXT.ITEM-BOUNDARY\n";
        mhtmlBottom += "Content-Location: " + images[i].location + "\n";
        mhtmlBottom += "Content-Type: " + images[i].type + "\n";
        mhtmlBottom +=
          "Content-Transfer-Encoding: " + images[i].encoding + "\n\n";
        mhtmlBottom += images[i].data + "\n\n";
      }
      mhtmlBottom += "--NEXT.ITEM-BOUNDARY--";

      //TODO: load css from included stylesheet
      var styles = "";

      // Aggregate parts of the file together
      var fileContent =
        static1.mhtml.top.replace(
          "_html_",
          static1.mhtml.head.replace("_styles_", styles) +
            static1.mhtml.body.replace("_body_", markup.html())
        ) + mhtmlBottom;
      // console.log(fileContent);
      // Create a Blob with the file contents
      var blob = new Blob([fileContent], {
        type: "application/msword;charset=utf-8",
      });
      saveAs(blob, fileName + ".doc");
    };
  })(jQuery);
} else {
  if (typeof jQuery === "undefined") {
    console.error("jQuery Word Export: missing dependency (jQuery)");
  }
  if (typeof saveAs === "undefined") {
    console.error("jQuery Word Export: missing dependency (FileSaver.js)");
  }
}
