<template>
<div 
    id="workbox"  v-loading="loading"
    :element-loading-text="this.loadingtext"
     >
    <top></top>
    <navMenu></navMenu>
    <div class="box2 clearfix">
    <div class="fl" :class="{ posifix: this.isScroll == true }">
      <left @handleLast="handleLast" :title="title1" />
    </div>
    <div class="fr clearfix">
      <right ref="right" />
    </div>

  </div>
</div>
 
</template>

<script>
import top from "@/components/navtop.vue";
import navMenu from "@/components/menuNav.vue";
import left from "@/components/testPaper/testLeft.vue";
import right from "@/components/testPaper/testRight.vue";

// import wordExport from '../../util/jquery.wordexport.js'
// import saveAs from "file-saver";
// window.saveAs = saveAs;
import $ from "@/util/jqueryexport.js";
import saveAs from "@/util/fileexport.js";
// import FileSaver from "@/util/FileSaver.js";

import wordExport from "@/util/jquery.wordexport.js";


export default {
  data() {
    return {
      loadtype: "1",
      isScroll: false,
      token: "",
      title: false,
      title1: 0,
      workdata: "",
      subhead: 0,
      workinfo: 0,
      stuinfo: 0,
      noticeitem: 0,
      secrecytitle: 0,
      gutterleft: 0,
      scoretable: 0,
      worddataa: "",
      xmltitle: "",
      zongfenshu: 0,
      papertype: "student",
      papersize: "A4",
      paperform: "DOC",
      vip: false,
      loading: false,
      loadingtext: "拼命下载中",
    };
  },
  components: {
     top,
    navMenu,
    left,
    right,
  },

  created() {
    this.listenerFunction();
    //判定是否是会员
    this.cardtype();
  },

  beforeDestroy() {
    document.removeEventListener("scroll", this.listenerFunction);
  },
  mounted() {
    this.token = JSON.parse(localStorage.getItem("loginInfo")).token
  },
  methods: {
    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    handleScroll() {
      if (window.pageYOffset >= 100) {
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
    },

    handleLast() {
      // console.log("12")
      this.loadcheck = true;
      // $('.paperright').find(".workopti").find("span").removeAttr("style");
      // 试卷代码
      this.loadtype = 2;
      let rightHtml = this.$refs.right.$el.innerHTML;
      var token = this.token
      this.loading = true;
      var examintitle = document.getElementById("questhead").innerText;
      console.log(this.vip,'vip');
      if (this.vip == true) {
         setTimeout(() => {
            $("#paperright").wordExport();
             this.loading = false
        }, 10000);
       
      }else{
          this.loading = false
          //暂无下载次数 是否进行充值
          this.$confirm("暂无权限下载是否进行充值？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$router.push("/voucherCenter");
            // console.log("1")
            // window.location.href =
            //   "http://www.rujiaowang.net/app/member/?t=" + token;
          }).catch(() => {
            // console.log("2")
            //取消 关闭弹窗
          });
      }
    },

    // 会员验证
    cardtype() {
      this.token = JSON.parse(localStorage.getItem("loginInfo")).token
      var typedata = {
        // token: window.atob(this.token),
        token: JSON.parse(localStorage.getItem("loginInfo")).token,
        md_id: 8,
      };
      this.$post(
        "http://api.rujiaowang.net/member/activate/card_type",
        typedata
      ).then((res) => {
        // console.log(res)
        //1 是会员 0 非会员
        if (res.result.status == 1) {
          this.vip = true;
          // console.log("true")
        } else {
          // console.log("false")
          this.vip = false;
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
.box2 {
  padding-top: 20px;
  width: 1200px;
  margin: 0 auto;
}
.fr {
  background: #fff;
  position: relative;
}
/deep/ .el-loading-mask {
  width: 100%;
  height: 100vh;
  height: calc(100vh -60px);
  position: fixed;
  z-index: 2000;
  background-color: rgba(255,255,255,.9);
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: opacity .3s;
}

//左侧吸顶样式
// .posifix{
//   position: fixed;
//   top: 0;
//   left: 19.3%;
// }
// @media screen and (min-width: 1600px){
//   .posifix{
//     left: 13.1%;
//   }
// }
// @media screen and (min-width: 1920px){
//   .posifix{
//     left: 19.3%;
//   }
// }
// left 13.1
</style>
