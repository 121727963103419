<template>
  <div class="Left">
    <!-- 试卷结构 -->
    <div style="display: none;">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="|| 试卷结构" name="1">
          <el-checkbox-group
            v-model="checkList"
            style="line-height: 30px"
            @change="ativechekc"
          >
            <el-row>
              <el-checkbox label="副标题"></el-checkbox>
              <el-checkbox label="考生信息"></el-checkbox>
            </el-row>
            <el-row>
              <!-- <el-checkbox label="总分栏"></el-checkbox> -->
              <el-checkbox label="试卷信息"></el-checkbox>
            </el-row>
            <el-row>
              <el-checkbox label="注意事项"></el-checkbox>
              <!-- <el-checkbox label="装订线"></el-checkbox> -->
            </el-row>
            <el-row>
              <el-checkbox label="保密标记"></el-checkbox>
              <!-- <el-checkbox label="分卷及注释"></el-checkbox> -->
            </el-row>
            <!-- <el-checkbox label="大题评分区"></el-checkbox> -->
          </el-checkbox-group>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div style="height: 5px"></div>
    <!-- 试卷信息 -->
    <div style="background-color: rgb(246, 250, 255)">
      <p class="testinfo">
        || 试卷信息
        <span v-show="this.sronumste == true">分数: {{ this.sronum }}分</span>
      </p>
      <div style="text-align: center; padding: 0 20px">
        <el-button class="download" @click="handleLast()">
          <i class="el-icon-download"></i> 下载试卷
        </el-button>
      </div>
      <!-- 试卷下载弹出框 -->

      <el-dialog title="下载确认" :visible.sync="dialogFormVisible">
        <el-form :model="form">
          <el-form-item label="文件格式：" :label-width="formLabelWidth">
            <el-radio-group v-model="form.format" @change="wordformat">
              <el-radio label="DOC"></el-radio>
              <el-radio label="DOCX"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="纸张大小：" :label-width="formLabelWidth">
            <div class="typeicolist clearfix">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <el-radio-group v-model="form.paper" @change="wordpaper">
              <el-radio label="A4"></el-radio>
              <el-radio label="A4(双栏)"></el-radio>
              <el-radio label="A3(双栏)"></el-radio>
              <el-radio label="B4(双栏)"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="试卷类型：" :label-width="formLabelWidth">
            <el-radio-group v-model="form.type" @change="wordtype">
              <el-row style="margin-bottom: 10px">
                <el-radio label="学生用卷(无答案)"></el-radio>
              </el-row>
              <el-row style="margin-bottom: 10px">
                <el-radio label="普通试卷(答案集中在卷尾)"></el-radio>
              </el-row>
              <el-row style="margin-bottom: 10px">
                <el-radio label="教师用卷(每题后面跟答案)"></el-radio>
              </el-row>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class="downtitle" v-show="this.downtype == 3"><span></span></div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleDownload()">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 题型 -->
      <div class="type">
        <draggable
          v-model="listtitle"
          @update="datadragEnd1"
          v-bind="dragOptions1"
        >
          <transition-group>
            <div
              v-for="(item, index) in listtitle"
              :key="index"
              class="bigQuestions"
            >
              <p style="padding: 10px 0">
                {{ list[index] }}{{ item.name }}
                <el-button
                  slot="reference"
                  size="mini"
                  class="fr"
                  type="text"
                  @click="handleDel(item, index)"
                  >删除</el-button
                >
              </p>
              <draggable
                v-model="data[item.name]"
                @update="datadragEnd"
                v-bind="dragOptions"
              >
                <transition-group>
                  <span
                    class="num"
                    size="mini"
                    v-for="(item1, index1) in data[item.name]"
                    :key="index1"
                    >{{ index1 + 1 }}</span
                  >
                </transition-group>
              </draggable>
            </div>
          </transition-group>
        </draggable>
      </div>
      <!-- 操作 -->
      <div class="operation">
        <el-row style="padding-bottom: 10px">
          <el-button type="primary" plain size="small" @click="storageexam"
            >暂存组卷</el-button
          >
          <!-- <el-button type="primary" plain size="small" @click="scoreset">分值设置</el-button> -->
          <!-- <el-button type="primary" plain size="small">试卷分析</el-button> -->
        </el-row>
        <el-row style="padding-bottom: 10px">
          <el-button
            type="primary"
            plain
            size="small"
            @click="holdon()"
            >继续挑题</el-button
          >
          <el-button type="primary" plain size="small" @click="clearPaper()"
            >清空试题</el-button
          >
        </el-row>
        <el-row style="padding-bottom: 20px">
          <!-- <el-button type="primary" plain size="small">下载答题卡</el-button> -->
        </el-row>
      </div>
      <!-- 温馨提示 -->
      <div style="padding: 0 20px 10px; color: #ccc; font-size: 12px">
        <p>温馨提示：</p>
        <p>
          1、拖拽试题题号即可调整试题顺序;
          <br />2、为了防止试题篮试题丢失，请点击“暂存组卷”。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import eventBus from "@/api/eventBus";
import draggable from "vuedraggable";
import url from "@/api/index.js";
import $ from "jquery"; //在需要使用的页面中
export default {
  inject: ["reload"],
  props: ["title"],
  data() {
    return {
      data: {},
      listtitle: [],
      list: [
        "一、",
        "二、",
        "三、",
        "四、",
        "五、",
        "六、",
        "七、",
        "八、",
        "九、",
        "十、",
      ],
      form: {
        paper: "A4",
        format: "DOC",
        type: "学生用卷(无答案)",
      },
      formmat: "",
      formtype: "",
      formpaper: "student",
      dialogFormVisible: false,
      formLabelWidth: "120px",
      activeNames: [],
      checkList: [],
      examlistdata: [],
      token: "",
      sronum: "",
      sronumste: false,
      paperform: "DOC",
      downloadnum: "0",
      downtype: 0,
    };
  },
  components: { draggable },
  computed: {
    // 拖拽配置
    dragOptions() {
      return {
        animation: 500,
        disabled: false,
        forceFallback: true,
        ghostClass: "ghost",
        chosenClass: "chosen",
        dragClass: "drag",
      };
    },
    // 大题
    dragOptions1() {
      return {
        animation: 500,
        disabled: false,
        forceFallback: true,
        ghostClass: "ghost",
        chosenClass: "chosen",
        dragClass: "drag",
      };
    },
  },
  // watch:{
  //   title:{
  //     handler:function (val,oldval) {
  //       console.log( val )
  //       if( val == true ){
  //         console.log(val,"title1")
  //         setTimeout(() => {
  //           this.storageexam()
  //         }, 1000);
  //       }
  //     }
  //   }
  // },
  mounted() {
    // console.log(this.title)
    this.downloadnum = localStorage.getItem("downnum");
    // console.log(this.downloadnum )
    if (this.downloadnum > 0) {
      this.downtype = 1;
    } else if (null != this.downloadnum) {
      this.downtype = 2;
    } else {
      this.downtype = 3;
    }
    // console.log(this.downtype)
    // console.log(this.downloadnum,"下载次数")
    this.token = JSON.parse(localStorage.getItem("loginInfo")).token
    this.data = JSON.parse(localStorage.getItem("shiti"));
    for (let key in this.data) {
      if (JSON.parse(localStorage.getItem("listtitle"))) {
        this.listtitle = JSON.parse(localStorage.getItem("listtitle"));
      } else {
        this.listtitle.push({
          name: key,
          scorearea: false,
        });
      }
    }

    // 右边拖拽 或者上下移时 重新获取数据
    eventBus.$on("dragright", () => {
      this.handledata();
    });
    // 拖拽
    document.body.ondrop = function(event) {
      event.preventDefault();
      event.stopPropagation();
    };
    //右侧分数更新，重新计算
    eventBus.$on("scorenum", () => {
      this.scorenum();
    });
    this.scorenum();

    let structstalist = localStorage.getItem("structsta");
    // console.log(structstalist)
    if (structstalist == null || structstalist == "") {
    } else {
      let strarry = structstalist.split(",");
      // console.log(strarry)
      this.checkList = strarry;
    }
  },
  methods: {
    //继续挑题
    holdon(){
      // localStorage.setItem('pages',JSON.stringify(0))
      // console.log(  )
      var url = JSON.parse(localStorage.getItem('route'))
      this.$router.push(url)
      
    },

    //format 文档格式 formmat:'',formtype:'',formpaper:'',
    wordformat(val) {
      // this.formmat = val
      if (val == "DOCX") {
        this.formmat = "docx";
      }
      if (val == "DOC") {
        this.formmat = "doc";
      }
      localStorage.setItem("paperform", this.formmat);
    },

    //纸张大小
    wordpaper(val) {
      // console.log(val);
      // B4(双栏)  A4  A3(双栏) A4(双栏)
      this.formtype = val;
      if (val == "A4") {
        this.formtype = "A4";
      }
      if (val == "A4(双栏)") {
        this.formtype = "A4H";
      }
      if (val == "A3(双栏)") {
        this.formtype = "A3H";
      }
      if (val == "B4(双栏)") {
        this.formtype = "B4H";
      }
      localStorage.setItem("papersize", this.formtype);
    },
    //试卷类型
    wordtype(val) {
      if (val == "普通试卷(答案集中在卷尾)") {
        this.formpaper = "normal";
      }
      if (val == "学生用卷(无答案)") {
        this.formpaper = "student";
      }
      if (val == "教师用卷(每题后面跟答案)") {
        this.formpaper = "teacher";
      }
      localStorage.setItem("papertype", this.formpaper);
    },
    //试卷分数
    scorenum() {
      this.data = JSON.parse(localStorage.getItem("shiti"));
      let scorenum = 0;
      //获取总分
      for (let name in this.data) {
        for (let inx in this.data[name]) {
          if (
            this.data[name][inx].inpval == "" ||
            this.data[name][inx].inpval == undefined
          ) {
            this.data[name][inx].inpval = 0;
          } else {
            scorenum += parseFloat(this.data[name][inx].inpval);
          }
          // console.log(this.data[name][inx].inpval,)
        }
        // console.log(this.data[name][inx].inpval)
      }
      this.sronum = scorenum;

      //判断分数是否有 否则不显示
      if (this.sronum == "") {
        this.sronumste = false;
      } else {
        this.sronumste = true;
      }
      // console.log(this.sronum)
    },
    // 试卷结构选择
    ativechekc(val) {
      if (val.length > 0) {
        eventBus.$emit("structlist", val);
      } else {
        eventBus.$emit("structlist", "");
      }
    },

    //暂存组卷
    storageexam() {
      var arr = Object.keys(this.data);
      var examlistdata = [];
      arr.forEach(function(res) {
        JSON.parse(localStorage.getItem("shiti"))[res].forEach(function(e) {
          examlistdata.push(e);
          // console.log(e)
        });
      });
      var content = new Array();
      var list = examlistdata;
      var flag = 0;
      var data = [];
      var quesids = [];
      for (var i = 0; i < list.length; i++) {
        quesids.push(list[i].question_id);
        var az = "";
        // console.log(data[j])
        for (var j = 0; j < data.length; j++) {
          if (data[j].head_title == list[i].type_name) {
            flag = 1;
            az = j;
            break;
          }
        }
        if (flag == 1) {
          data[az].scores.splice(0, 0, [
            { ques_id: list[i].question_id, scores: 2, sub_scores: 2 },
          ]);
          data[az].questions.splice(0, 0, list[i].question_id);
          flag = 0;
        } else if (flag == 0) {
          var wdy = new Array();
          data.push({
            head_title: list[i].type_name,
            scores: [
              { ques_id: list[i].question_id, scores: 2, sub_scores: 2 },
            ],
            questions: [list[i].question_id],
          });
        }
      }
      // console.log(quesids)
      this.examlistdata = data;
      // console.log(data)

      this.getexam(data, quesids);
    },

    //继续挑题
    holdon(){
      // this.retruework()
      var url = JSON.parse(localStorage.getItem('zjpages'))
      this.$router.push(url)
    },

    //组卷列表 
    getexam(data,queslist){
      // console.log(data)
      // console.log( localStorage.getItem("questtitle"))
      var examintitle = document.getElementById("questhead").innerText;
      this.$post(url.saveexam, {
        content: JSON.stringify(data),
        token: this.token,
        // "token": "401c99ed4ac8d300bca6455235d3aef9",
        xd_id: "true",
        sub_id: "true",
        title: examintitle,
        ques_ids: JSON.stringify(queslist),
      }).then((res) => {
        var examid = res.result;
        localStorage.setItem("examid", examid);
        this.selectbtn(examid);
      });
    },
    selectbtn(examid) {
      this.$post(url.finishexam, {
        // "token": "401c99ed4ac8d300bca6455235d3aef9",
        token: this.token,
        exam_id: examid,
      }).then((uesrexam) => {
        //是否保存成功
        var stetitle = {};
        if (uesrexam.msg == "成功") {
          stetitle.name = "保存成功";
          stetitle.type = "success";
        } else {
          stetitle.name = "保存失败";
          stetitle.type = "warning";
          this.$message({
            message: stetitle.name,
            type: stetitle.type,
          });
        }
        // this.$message({
        //   message: stetitle.name,
        //   type: stetitle.type,
        // });
      });
    },
    handleLast() {
      var userinfo = JSON.parse(localStorage.getItem("loginInfo")).token
      //下载前先判断是否登录
      if (userinfo == null) {
        this.$message.error("请登录后操作!");
      } else {
        this.storageexam();
        localStorage.removeItem("papertype");
        localStorage.removeItem("papersize");
        localStorage.removeItem("paperform");
        this.downloadnum = localStorage.getItem("downnum");
        if (this.downloadnum > 0) {
          this.downtype = 1;
        } else if (null != this.downloadnum) {
          this.downtype = 2;
        } else {
          this.downtype = 3;
        }
        this.$nextTick(async function() {

          await this.$emit("renderData", 1);
          await this.mathToImage();
          await this.$emit("loadingFlag", true);
          var testName = $("#questhead").text();
          // console.log(testName);
          // localStorage.getItem("UserToken");
          localStorage.setItem("testName", $("#questhead").text())
          // this.downloadwork() //数据上报  下载
          this.$emit("handleLast"); 
        });
      }
    },
    // 获取数据
    handledata() {
      this.data = JSON.parse(localStorage.getItem("shiti"));
    },
    handleDel(item, index) {
      // console.log(item)
      this.$confirm("确认删除整个大题吗？")
        .then(() => {
          // 删除后长度
          var shitilength = JSON.parse(localStorage.getItem("shitilength"));
          var shitinewlength = Number(shitilength) - Number(this.data[item.name].length);
          localStorage.setItem("shitilength", JSON.stringify(shitinewlength));

          delete this.data[item.name];
          localStorage.setItem("shiti", JSON.stringify(this.data));

          var listtitle = JSON.parse(localStorage.getItem("listtitle"))
          if (listtitle) {
            listtitle.splice(index, 1);
            // console.log(listtitle);
            localStorage.setItem("listtitle", JSON.stringify(listtitle));
          }

          if (
            localStorage.getItem("shiti") == "{}" ||
            localStorage.getItem("shiti") == null
          ) {
            localStorage.removeItem("shitilength");
            localStorage.removeItem("listtitle");
            this.$router.push("ChapterTopics");
          } else {
            // console.log("you")
          }

          this.reload();
        })
        .catch(() => {});
    },
    //下载组卷
    handleDownload() {
      // console.log(this.form);

      this.dialogFormVisible = false;
    },
    handleChange(val) {
      // console.log(val);
    },
    // 拖拽
    datadragEnd(evt) {
      evt.preventDefault();
      localStorage.setItem("shiti", JSON.stringify(this.data));
      // 左边拖拽 通知右边重新获取
      eventBus.$emit("drag");
    },
    // 大题拖拽
    datadragEnd1(evt) {
      evt.preventDefault();
      // 左边拖拽 通知右边重新获取
      eventBus.$emit("listtitle", this.listtitle);
    },
    // 清空试题 
    clearPaper() {
      this.$confirm("确认要清空试卷吗？")
        .then(() => {
          // this.retruework()
          localStorage.removeItem("shiti");
          localStorage.removeItem("shitilength");
          localStorage.removeItem("listtitle");
          localStorage.removeItem("structsta");
          this.$router.push("ChapterTopics");
        })
        .catch(() => {});
    },

    // 公式转图片
    mathToImage() {
      // setTimeout(() => {
      //         console.log("222");
      // console.log($(".mjx-chtml"));
      // console.log($(".mjx-chtml").length);
      $(".disno").each(function() {
        if ($(this).hasClass("disno")) {
          $(this).remove();
        }
      });
      if ($(".mjx-chtml").find("img").length == 0) {
        for (let i = 0; i < $(".mjx-chtml").length; i++) {
          // console.log(
          //   $(".mjx-chtml")
          //     .eq(i)
          //     .find("img").length == 0
          // );
          if (
            $(".mjx-chtml")
              .eq(i)
              .find("img").length == 0
          ) {
            html2canvas($(".mjx-chtml").eq(i)[0], {
              // width: null //避免图片有白色边框
              scale: 0.8,
              height: $(".mjx-chtml").eq(i)[0].offsetHeight * 1.1,
              width: $(".mjx-chtml").eq(i)[0].offsetWidth * 1.1,
              // margin: 5,
              // padding: 5,
            }).then((canvas) => {
              // console.log('222');
              // console.log($(".mjx-chtml").eq(i)[0]);
              // console.log(canvas);
              // canvas.style.width = canvas.style.width * 0.5;
              // canvas.style.padding = "5";
              // canvas.style.margin = "5";
              // console.log(canvas);
              const image = canvas.toDataURL("image/png");
              // var uri=image
              // console.log(image);
              const pHtml =
                "<img src=" + image + " style='width:80%;height:80%;'/>";
              // console.log(pHtml);
              $(".MathJax_CHTML")
                .eq(i)
                .html(pHtml);
              $(".mjx-chtml")
                .eq(i)
                .parents("p")
                .attr({
                  style: "display:flex;align-items:center;letter-spacing: 0px;",
                });
              //  console.log($('.mjx-chtml').eq(i)[0]);
              // console.log($('.MathJax'));
              // let dom = document.body.appendChild(canvas);
              // dom.style.display = 'none';
              // a.style.display = 'none';
              // document.body.removeChild(dom);
              // let blob = that.dataURLToBlob(dom.toDataURL('image/png'));
              // a.setAttribute('href', URL.createObjectURL(blob));
              // //这块是保存图片操作  可以设置保存的图片的信息
              // a.setAttribute('download', imgText + '.png');
              // document.body.appendChild(a);
              // a.click();
              // URL.revokeObjectURL(blob);
              // document.body.removeChild(a);
            });
          } else {
            // console.log("return");
            // return false;
          }
        }
      }
      // }, 1000);
    },

    // 数据上报
    // 下载
    downloadwork(){
      var iteminfo = JSON.parse(localStorage.getItem("loginInfo"));
      axios.get("http://access.rujiaowang.net/GetLogServer").then((res) => {
        this.client_ip = res.data.result.client_ip;
        var differenctime = res.data.result.net_ts - Date.parse(new Date()) / 1000;
        
        var saydata = {
          product_name: "教师工作台",
          log_version: "v2.0.1",
          action: "testgroup_download",
          user_id: String(iteminfo.user_id),
          user_role: String(iteminfo.user_type),
          user_name: String(iteminfo.user_nicename),
          ts: String(Number(Date.parse(new Date())) / 1000 + differenctime),
          trace_id: JSON.parse(localStorage.getItem("data_traceid")),
          dev_id: "",
          dev_name: "",
          client_ip: this.client_ip,
          os: "",
          cpu_name: "",
          cpu_use: "",
          app_version: "v2.0.0",
          mem_size: "",
          mem_use: "",
          net: "",
          electric: "",
          extend: "",
          remark: `${iteminfo.user_nicename}` + "使用了组卷的下载试卷功能",
          data: {
            school_name: iteminfo.teacher_info.school_name
            ? `${iteminfo.teacher_info.school_name}`
            : "",
            school_id: iteminfo.teacher_info.school_id
            ? `${iteminfo.teacher_info.school_id}`
            : "",
            trace_id: JSON.parse(localStorage.getItem("prepare_traceid")),
          },
        }
        axios.post("http://47.114.114.56:9002/RecordRjw", saydata).then(res =>{})
      })
    },
    // 退出
    retruework(){
      var iteminfo = JSON.parse(localStorage.getItem("loginInfo"));
      axios.get("http://access.rujiaowang.net/GetLogServer").then((res) => {
        this.client_ip = res.data.result.client_ip;
        var differenctime = res.data.result.net_ts - Date.parse(new Date()) / 1000;
        
        var saydata = {
          product_name: "教师工作台",
          log_version: "v2.0.1",
          action: "testgroup_centreexit",
          user_id: String(iteminfo.user_id),
          user_role: String(iteminfo.user_type),
          user_name: String(iteminfo.user_nicename),
          ts: String(Number(Date.parse(new Date())) / 1000 + differenctime),
          trace_id: JSON.parse(localStorage.getItem("data_traceid")),
          dev_id: "",
          dev_name: "",
          client_ip: this.client_ip,
          os: "",
          cpu_name: "",
          cpu_use: "",
          app_version: "v2.0.0",
          mem_size: "",
          mem_use: "",
          net: "",
          electric: "",
          extend: "",
          remark: `${iteminfo.user_nicename}` + "退出了组卷的组卷中心",
          data: {
            school_name: iteminfo.teacher_info.school_name
            ? `${iteminfo.teacher_info.school_name}`
            : "",
            school_id: iteminfo.teacher_info.school_id
            ? `${iteminfo.teacher_info.school_id}`
            : "",
            trace_id: JSON.parse(localStorage.getItem("prepare_traceid")),
          },
        }
        axios.post("http://47.114.114.56:9002/RecordRjw", saydata).then(res =>{})
      })
    }
  },
};
</script>

<style lang="less" scoped>
.Left {
  width: 220px;
  //
}
.el-collapse /deep/ .el-collapse-item__header {
  padding-left: 20px;
  font-size: 16px;
  background-color: rgb(240, 247, 255);
}
.el-collapse /deep/ .el-collapse-item__content {
  padding: 10px 20px;
  background-color: rgb(246, 250, 255);
}
.testinfo {
  height: 50px;
  background-color: rgb(240, 247, 255);
  line-height: 50px;
  padding-left: 20px;
}
.download {
  width: 100%;
  height: 40px;
  background-color: rgb(255, 159, 0);
  color: #fff;
  font-size: 16px;
  border: 1px solid #ff9f00;
}

.type {
  padding: 0 5px 15px;
  border-bottom: 1px solid #f1f3f7;
  .bigQuestions {
    border: 1px solid transparent;
    margin: 10px 0;
    padding: 0 20px;
  }
  .bigQuestions:hover {
    border: 1px solid #ff9f00;
  }
  .num {
    display: inline-block;
    border: 1px solid #b9b4b4;
    margin: 0 10px 10px 0;
    padding: 0px 6px;
    color: #409eff;
    border-radius: 5px;
  }
}
.operation {
  padding: 20px 20px 0;
  border-bottom: 1px solid #f1f3f7;
  .el-row .el-button + .el-button {
    margin-left: 20px;
  }
}
.fr {
  float: right;
  margin: 0 !important;
}
.ghost {
  opacity: 0 !important;
  background: transparent !important;
}

.drag {
  opacity: 1 !important;
}
.el-form-item {
  border-bottom: 1px dashed #ccc;
}
.el-dialog__header {
  padding: 12px 20px 10px;
  border-radius: 5px 5px 0 0;
  background-color: #3a8ee6;
}
.el-dialog__title {
  line-height: 24px;
  font-size: 18px;
  color: #ffffff;
}
.el-dialog__wrapper /deep/ .el-dialog {
  border-radius: 6px !important;
  margin-top: 36vh !important;
  width: 30% !important;
}
.el-form-item__label {
  font-size: 16px;
  padding: 0 30px 0 0;
}
.el-dialog__body {
  padding: 30px 30px;
}
</style>
