<template>
  <div class="right paperright" id="paperright">
    <div
      style=" text-align: center; padding: 30px 0 10px 0; font-size: 20px; font-weight: 700; heihgt:40px; line-height:40px; "
    >
      <div
        @click="questiontitle()"
        id="questhead"
        contenteditable="true"
        class="questtitle"
      >
        {{ this.presenttime }}
      </div>
    </div>

    <div v-for="(item, index) in listtitle" :key="index">
      <div class="clearfix" style="margin: 20px 0 20px 0;">
        <div style=" font-size: 18px;" class="fenshunum">
          {{ list[index] }}{{ item.name }} （ 共{{
            data[item.name] ? data[item.name].length : 0
          }}小题 ）
        </div>
      </div>
      <draggable
        v-model="data[item.name]"
        @update="datadragEnd"
        v-bind="dragOptions"
        :scroll="true"
      >
        <transition-group>
          <div
            v-for="(itemIn, indexIn) in data[item.name]"
            :key="indexIn"
            class="hover workcontrol"
          >
            <div class="clearfix">
              {{ indexIn + 1 }}、
              <span
                v-html="itemIn.question_text"
                style="padding: 0 10px 0px 10px;"
              ></span>
            </div>
            <p v-html="itemIn.options_json" class="workopti"></p>
            <div class="manage disno">
              <span class="cutmark" style="margin-left:5px;display:none"
                >分</span
              >
              <span
                @click="moveUp(data[item.name], itemIn, indexIn)"
                v-if="indexIn !== 0"
                >上移</span
              >
              <span
                @click="moveDown(data[item.name], itemIn, indexIn)"
                v-if="indexIn !== data[item.name].length - 1"
                >下移</span
              >

              <span @click="del(data[item.name], itemIn, indexIn)">删除</span>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import eventBus from "@/api/eventBus";
import draggable from "vuedraggable";
import url from "@/api/index.js";
import $ from "jquery"; //在需要使用的页面中
export default {
  inject: ["reload"],
  props: ["download", "Num"],
  data() {
    return {
      data: {},
      listtitle: [],
      list: [
        "一、",
        "二、",
        "三、",
        "四、",
        "五、",
        "六、",
        "七、",
        "八、",
        "九、",
        "十、",
      ],
      presenttime: "",
      noticeitem:
        "1．答题前填写好自己的姓名、班级、考号等信息 2．请将答案正确填写在答题卡上",
      questlist: [],
      checkval: "",
      secrecy: false,
      studentinfo: false,
      numlist: false,
      questinfo: false,
      title: false,
      gutter: false,
      notice: false,
      secrecy: false,
      annotation: false,
      scorearea: false,
      stetype: [],
      imgsrc:
        "http://114.217.148.212:8004/testpaper/test/img/peal_line.4282ec00.png",
      datalist: [],
      datanum: 0,
    };
  },
  components: { draggable },
  computed: {
    // 拖拽方法
    dragOptions() {
      return {
        animation: 500,
        // group: "description", 禁止大题之间的拖拽
        disabled: false,
        ghostClass: "ghost",
        chosenClass: "chosen",
        dragClass: "drag",
        forceFallback: true,
      };
    },
  },
  created() {
    eventBus.$on("structlist", (name) => {
      localStorage.setItem("structsta", name);
      var i = 0;
      if (name.length == 0 || name.length == "") {
        this.checkval == "nullval";
        this.secrecy = false;
        this.studentinfo = false;
        this.numlist = false;
        this.questinfo = false;
        this.scorearea = false;
        this.title = false;
        this.gutter = false;
        this.notice = false;
        this.secrecy = false;
        this.annotation = false;
      } else {
        var that = this;
        // this.checkval = name
        if (name.includes("副标题")) {
          this.title = true;
        } else {
          this.title = false;
        }
        if (name.includes("考生信息")) {
          this.studentinfo = true;
        } else {
          this.studentinfo = false;
        }
        if (name.includes("总分栏")) {
          this.numlist = true;
        } else {
          this.numlist = false;
        }
        if (name.includes("试卷信息")) {
          this.questinfo = true;
        } else {
          this.questinfo = false;
        }
        if (name.includes("装订线")) {
          this.gutter = true;
        } else {
          this.gutter = false;
        }
        if (name.includes("注意事项")) {
          this.notice = true;
        } else {
          this.notice = false;
        }
        if (name.includes("保密标记")) {
          this.secrecy = true;
        } else {
          this.secrecy = false;
        }
        // if( name.includes("分卷及注释") ){ this.annotation = true }else{ this.annotation = false }
        // if( name.includes("大题评分区") ){ this.scorearea = true}else{ this.scorearea = false }
        // console.log(name)
        if(  name.includes("大题评分区") ){
          this.listtitle.forEach(  res => {
            // console.log(res)
            res.scorearea = true
            this.scorearea = true
          })
        }else{
          this.listtitle.forEach(  res => {
            res.scorearea = false
            this.scorearea = false
          })
        }
      }
    });
  },
  mounted() {
//     setTimeout(() => {
//       //         console.log("222");
//       // console.log($(".mjx-chtml"));
//       // console.log($(".mjx-chtml").length);
//       // $(".disno").each(function() {
//       //   if ($(this).hasClass("disno")) {
//       //     $(this).remove();
//       //   }
//       // });
//       if($(".mjx-chtml")
//             .find("img").length == 0){
//               console.log($(".mjx-chtml"));
//         for (let i = 0; i < $(".mjx-chtml").length; i++) {
//         console.log(
//           $(".mjx-chtml")
//             .eq(i)
//             .find("img").length == 0
//         );
//          if($(".mjx-chtml").eq(i)
//             .find("img").length == 0){
//           html2canvas($(".mjx-chtml").eq(i)[0], {
//             // width: null //避免图片有白色边框
//             scale: 0.9,
//             height: $(".mjx-chtml").eq(i)[0].offsetHeight * 1.3,
//             width: $(".mjx-chtml").eq(i)[0].offsetWidth * 1.3,
//             // margin: 5,
//             // padding: 5,
//           }).then((canvas) => {
//             // console.log('222');
//             // console.log($(".mjx-chtml").eq(i)[0]);
//             // console.log(canvas);
//             // canvas.style.width = canvas.style.width * 0.5;
//             // canvas.style.padding = "5";
//             // canvas.style.margin = "5";
//             console.log(canvas);
//             const image = canvas.toDataURL("image/png");
//             // var uri=image
//             console.log(image);
//             const pHtml =
//               "<img src=" + image + " style='width:100%;height:100%;'/>";
//             // console.log(pHtml);
//             $(".MathJax_CHTML")
//               .eq(i)
//               .html(pHtml);
//             $(".mjx-chtml")
//               .eq(i)
//               .parents("p")
//               .attr({
//                 style: "display:flex;align-items:center;letter-spacing: 0px;",
//               });
//             //  console.log($('.mjx-chtml').eq(i)[0]);
//             // console.log($('.MathJax'));
//             // let dom = document.body.appendChild(canvas);
//             // dom.style.display = 'none';
//             // a.style.display = 'none';
//             // document.body.removeChild(dom);
//             // let blob = that.dataURLToBlob(dom.toDataURL('image/png'));
//             // a.setAttribute('href', URL.createObjectURL(blob));
//             // //这块是保存图片操作  可以设置保存的图片的信息
//             // a.setAttribute('download', imgText + '.png');
//             // document.body.appendChild(a);
//             // a.click();
//             // URL.revokeObjectURL(blob);
//             // document.body.removeChild(a);
//           });
// }
//       }
//       }
//     }, 1000);
//     this.getstructsta();
    this.getNowFormatDate();
    // 初次渲染数据
    let data = JSON.parse(localStorage.getItem("shiti"));
    for (let key in data) {
      if (JSON.parse(localStorage.getItem("listtitle"))) {
        // ......
        this.listtitle = JSON.parse(localStorage.getItem("listtitle"));
      } else {
        // this.listtitle.push(key);
        this.listtitle.push({
          name: key,
          scorearea: false,
        });
        // var asd = []
        this.stetype.push({
          name: key,
          scorearea: false,
        });
        // console.log(this.listtitle)
      }

      var fontfamily = "";
      for (var j = 0; j < data[key].length; j++) {
        var text = data[key][j].question_text;
        var opti = data[key][j].options_json;
        // var tihuan = new RegExp( 'Times New Roman' , "g" )
        // fontfamily = opti.replace( tihuan, '微软雅黑' )

        text = text.replace(/<p>*?</, `<p>${j + 1}、\<`);
        // console.log(text)
        // data[key][j].options_json = fontfamily;
        // window.MathJax.Hub.Queue([
        //   "Typeset",
        //   MathJax.Hub,
        //   document.getElementById("paperright"),
        // ]);
        // setTimeout(() => {
        //   $(".paperright")
        //     .find("img")
        //     .attr("alt", " ");
        // }, 500);
      }
    }

    //初始化判断大题评分区
    var structstadata = localStorage.getItem("structsta");
    if (structstadata != null) {
      let strarry = structstadata.split(",");
      if (strarry.includes("大题评分区")) {
        this.listtitle.forEach((res) => {
          res.scorearea = true;
        });
        this.scorearea = true;
      } else {
        this.listtitle.forEach((res) => {
          res.scorearea = false;
        });
        this.scorearea = false;
      }
    }

    this.data = data;
    localStorage.setItem("shiti", JSON.stringify(this.data));
    // 左边拖拽题号
    eventBus.$on("drag", () => {
      this.handleSwip();
    });

    // 大题 拖拽
    eventBus.$on("listtitle", (listtitle) => {
      eventBus;
      this.listtitle = listtitle;
      localStorage.setItem("listtitle", JSON.stringify(this.listtitle));
      // console.log(this.listtitle);
      // console.log(listtitle,"大题拖拽");
      this.reload();
      // console.log("21")
    });
    // 拖拽 防止默认事件 冒泡事件
    document.body.ondrop = function(event) {
      event.preventDefault();
      event.stopPropagation();
    };

    // 总分栏循环
    var questtitle = Object.keys(this.data);
    var qil = 0;
    for (qil; qil < questtitle.length; qil++) {
      this.questlist.push({
        name: questtitle[qil],
        number: qil + 1,
      });
    }
    // console.log(this.questlist)

    // this.$emit('childByValue', this.childValue)
    // questsbutitle
    //试题兼容

    //     setTimeout(() => {
    //       this.$nextTick(async function() {
    //         //这里要注意，使用$nextTick等组件数据渲染完之后再调用MathJax渲染方法，要不然会获取不到数据
    //         // if (this.commonsVariable.isMathjaxConfig) {
    //         //判断是否初始配置，若无则配置。
    //         // this.commonsVariable.initMathjaxConfig();
    //         // }
    //         // this.commonsVariable.MathQueue("app");//传入组件id，让组件被MathJax渲染
    //         console.log("222");
    //         console.log($(".mjx-chtml"));
    //         console.log($(".mjx-chtml").length);
    //         // setTimeout(function () {
    //         // for (let i = 0; i < 1; i++) {
    //         // console.log($('.mjx-chtml').eq(0)[0]);
    //         // html2canvas($('.mjx-chtml').eq(i)[0], {
    //         //       onrendered: function (canvas) {
    //         //         console.log($('.mjx-chtml').eq(i)[0]);
    //         //         console.log(canvas)
    //         //         const image = canvas.toDataURL("image/png");
    //         //         // var uri=image
    //         //         // console.log(image);
    //         //         const pHtml = "<img src=" + image + " style='height: 100%;margin:0 0px;'/>";
    //         //             $('.mjx-chtml').eq(i)[0].html(pHtml);
    //         //             // console.log($('.MathJax'));
    //         //       },
    //         //     });
    // /*         console.log($(".mjx-chtml").eq(0));
    //         console.log($(".mjx-chtml").eq(0)[0]);
    //         console.log(html2canvas($(".mjx-chtml").eq(0)[0]));
    //         const res = await html2canvas($(".mjx-chtml").eq(0)[0]);
    //         console.log(res);
    //         const image = res.toDataURL("image/png");
    //         console.log(image);
    //         const pHtml =
    //           "<img src=" + image + " style='height: 100%;margin:0 5px;'/>";
    //         console.log(pHtml);
    //         $(".mjx-chtml")
    //           .eq(0)
    //           .html(pHtml); */
    //                html2canvas($(".mjx-chtml").eq(0)[0]).then((canvas) => {
    //           // console.log('222');
    //           console.log($(".mjx-chtml").eq(0)[0]);
    //           console.log(canvas);
    //           const image = canvas.toDataURL("image/png");
    //           // var uri=image
    //           console.log(image);
    //           const pHtml =
    //             "<img src=" + image + " style='height: 20px;margin:0 5px;'/>";
    //           console.log(pHtml);
    //           $(".mjx-chtml")
    //             .eq(0)
    //             .html(pHtml);
    //           //  console.log($('.mjx-chtml').eq(i)[0]);
    //           // console.log($('.MathJax'));
    //           // let dom = document.body.appendChild(canvas);
    //           // dom.style.display = 'none';
    //           // a.style.display = 'none';
    //           // document.body.removeChild(dom);
    //           // let blob = that.dataURLToBlob(dom.toDataURL('image/png'));
    //           // a.setAttribute('href', URL.createObjectURL(blob));
    //           // //这块是保存图片操作  可以设置保存的图片的信息
    //           // a.setAttribute('download', imgText + '.png');
    //           // document.body.appendChild(a);
    //           // a.click();
    //           // URL.revokeObjectURL(blob);
    //           // document.body.removeChild(a);
    //         });
    //         // }
    //         // },2000);
    //       });
    //     }, 500);
  },
  methods: {
    //试卷结构缓存
    getstructsta() {
      let structstalist = localStorage.getItem("structsta");
      if (structstalist == null) {
      } else {
        let strarry = structstalist.split(",");
        if (strarry.includes("副标题")) {
          this.title = true;
        } else {
          this.title = false;
        }
        if (strarry.includes("考生信息")) {
          this.studentinfo = true;
        } else {
          this.studentinfo = false;
        }
        if (strarry.includes("总分栏")) {
          this.numlist = true;
        } else {
          this.numlist = false;
        }
        if (strarry.includes("试卷信息")) {
          this.questinfo = true;
        } else {
          this.questinfo = false;
        }
        if (strarry.includes("装订线")) {
          this.gutter = true;
        } else {
          this.gutter = false;
        }
        if (strarry.includes("注意事项")) {
          this.notice = true;
        } else {
          this.notice = false;
        }
        if (strarry.includes("保密标记")) {
          this.secrecy = true;
        } else {
          this.secrecy = false;
        }
        if (strarry.includes("大题评分区")) {
          this.listtitle.forEach((res) => {
            res.scorearea = true;
            this.scorearea = true;
          });
        } else {
          this.listtitle.forEach((res) => {
            res.scorearea = false;
            this.scorearea = true;
          });
        }
        eventBus.$emit("structstate", strarry);
      }
    },

    //每题多少分
    valinp(res, val) {
      // console.log(res,val)
      let data = JSON.parse(localStorage.getItem("shiti"));
      let scoreval = val;
      for (let key in data) {
        if (key == res.name) {
          for (let sv in data[key]) {
            // console.log(data[key][sv].inpval)
            // console.log(sv,data[key].length)
            // data[key][sv].inpval = scoreval / data[key].length
            // data[key][sv].inpnum = scoreval
            data[key][sv].inpval = scoreval;
            localStorage.setItem("shiti", JSON.stringify(data));
          }
        }
      }
      //处理更新通知左边
      eventBus.$emit("scorenum");
      // console.log(JSON.parse(localStorage.getItem("shiti")))
    },
    //获取分数
    valchange(val, item) {
      // console.log(item)
      this.datalist.push(val);
      let data = JSON.parse(localStorage.getItem("shiti"));
      var intval = parseInt(val);
      // this.listtitle.forEach(  res => {
      //   res.scoreval= val+1
      // })
      let datanum = 0;
      for (let key in data) {
        if (key == item.type_name) {
          //试题类型一样
          for (let datali in data[key]) {
            //循环缓存数据
            //当前数据和缓存数据id一样  保存当前分数值
            if (data[key][datali].id == item.id) {
              data[key][datali].inpval = val;
            } else {
              // console.log("错")
            }
            localStorage.setItem("shiti", JSON.stringify(data));
          }
        } else {
          // console.log("否")
        }
      }
      //数据便利循环  分数相加
      for (let key in data) {
        //是否是同类型数据
        if (key == item.type_name) {
          for (let datainx in data[key]) {
            //相同类型获取分数相加得总分数
            if (
              data[key][datainx].inpval == null ||
              data[key][datainx].inpval == ""
            ) {
              data[key][datainx].inpval = 0;
              datanum += parseInt(data[key][datainx].inpval);
            } else {
              datanum += parseInt(data[key][datainx].inpval);
            }
            data[key][datainx].inpnum = datanum;
          }
        }
      }
      localStorage.setItem("shiti", JSON.stringify(data));
      this.data = JSON.parse(localStorage.getItem("shiti"));
      // console.log(data)
    },

    //时间过滤 文件名字定义
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      this.presenttime = `${year}年${month}月${strDate}日` + "试卷";
      localStorage.setItem("questtitle", this.presenttime);
      return currentdate;
    },
    questiontitle() {
      // console.log(11111);
      // console.log(this.presenttime)
    },
    // 上移
    moveUp(data, item, index) {
      // console.log(1111);
      this.handleCurrent(data, item, index, 0);
    },
    // 下移
    moveDown(data, item, index) {
      // console.log(data,item,"下移");
      this.handleCurrent(data, item, index, 1);
    },
    // 删除
    del(data, item, index) {
      var questlist = localStorage.getItem("shitilength");
      this.$confirm("确认删除该题吗？")
        .then(() => {
          var i = item.question_type;
          this.data[i].splice(index, 1);
          
          // console.log(this.data[i])
          if(this.data[i].length == 0){

            localStorage.setItem("shiti", JSON.stringify(this.data));
            if(JSON.parse(localStorage.getItem("listtitle"))){
              var listtitle = JSON.parse(localStorage.getItem("listtitle"))
              for (let index = 0; index < listtitle.length; index++) {
                // console.log(listtitle[index].name , i);
                if(listtitle[index].name == i){
                  listtitle.splice(index,1)
                }
              }
              localStorage.setItem('listtitle',JSON.stringify(listtitle))
            }

            delete this.data[i]

            this.reload()
          } else {
            localStorage.setItem("shiti", JSON.stringify(this.data));
          }
          // 缓存数据长度修改
          questlist--;
          // console.log(questlist)
          localStorage.setItem("shitilength", questlist);

          if (questlist == 0) { //清空
            this.$router.push("/testPaper/ChapterTopics");
            localStorage.removeItem("shiti");
            localStorage.removeItem("shitilength");
            localStorage.removeItem("listtitle");
          }

          this.handleSwip(); //删除 eventBus
        })
        .catch(() => {});
    },
    // 封装操作数组方法
    swapItems(data, index1, index2) {
      data[index1] = data.splice(index2, 1, data[index1])[0];
      return data;
    },
    handleCurrent(data, item, index, type) {
      let newArr = this.swapItems(data, index, type ? index + 1 : index - 1);
      var i = item.type_name;
      let datac = this.data;
      data[i] = newArr;
      localStorage.setItem("shiti", JSON.stringify(datac));
      this.handleSwip();
    },
    // 拖拽
    datadragEnd(evt) {
      evt.preventDefault();
      // console.log("拖动前的索引 :" + evt.oldIndex);
      // console.log("拖动后的索引 :" + evt.newIndex);
      // console.log("移动了");
      localStorage.setItem("shiti", JSON.stringify(this.data));
      this.handleSwip();
      this.reload();
    },

    handleSwip() {
      let data = JSON.parse(localStorage.getItem("shiti"));
      for (let key in data) {
        for (var q = 0; q < data[key].length; q++) {
          let text = data[key][q].question_text;
          text = text.replace(/<p>*?</, `<p>${q + 1}、\<`);
          // data[key][q].question_text = text;
        }
      }

      this.data = data;
      localStorage.setItem("shiti", JSON.stringify(this.data));
      // 数据变动，通知左边
      eventBus.$emit("dragright");

      // window.MathJax.Hub.Queue([
      //   "Typeset",
      //   MathJax.Hub,
      //   document.getElementById("paperright"),
      // ]);
      // setTimeout(() => {
      //   for (let i = 0; i < $(".mjx-chtml").length; i++) {
      //     console.log(
      //       $(".mjx-chtml")
      //         .eq(i)
      //         .find("img").length == 0
      //     );
      //     if (
      //       $(".mjx-chtml")
      //         .eq(i)
      //         .find("img").length == 0
      //     ) {
      //       html2canvas($(".mjx-chtml").eq(i)[0], {
      //         // width: null //避免图片有白色边框
      //         scale: 0.9,
      //         height: $(".mjx-chtml").eq(i)[0].offsetHeight * 1.3,
      //         width: $(".mjx-chtml").eq(i)[0].offsetWidth * 1.3,
      //         // margin: 5,
      //         // padding: 5,
      //       }).then((canvas) => {
      //         // console.log('222');
      //         // console.log($(".mjx-chtml").eq(i)[0]);
      //         // console.log(canvas);
      //         // canvas.style.width = canvas.style.width * 0.5;
      //         // canvas.style.padding = "5";
      //         // canvas.style.margin = "5";
      //         console.log(canvas);
      //         const image = canvas.toDataURL("image/png");
      //         // var uri=image
      //         console.log(image);
      //         const pHtml =
      //           "<img src=" + image + " style='width:100%;height:100%;'/>";
      //         // console.log(pHtml);
      //         $(".MathJax_CHTML")
      //           .eq(i)
      //           .html(pHtml);
      //         $(".mjx-chtml")
      //           .eq(i)
      //           .parents("p")
      //           .attr({
      //             style: "display:flex;align-items:center;letter-spacing: 0px;",
      //           });
      //         //  console.log($('.mjx-chtml').eq(i)[0]);
      //         // console.log($('.MathJax'));
      //         // let dom = document.body.appendChild(canvas);
      //         // dom.style.display = 'none';
      //         // a.style.display = 'none';
      //         // document.body.removeChild(dom);
      //         // let blob = that.dataURLToBlob(dom.toDataURL('image/png'));
      //         // a.setAttribute('href', URL.createObjectURL(blob));
      //         // //这块是保存图片操作  可以设置保存的图片的信息
      //         // a.setAttribute('download', imgText + '.png');
      //         // document.body.appendChild(a);
      //         // a.click();
      //         // URL.revokeObjectURL(blob);
      //         // document.body.removeChild(a);
      //       });
      //     } else {
      //       // console.log("return");
      //       // return false;
      //       $(".MathJax_CHTML")
      //         .eq(i)
      //         .html($(".MathJax_CHTML").eq(i));
      //       $(".mjx-chtml")
      //         .eq(i)
      //         .parents("p")
      //         .attr({
      //           style: "display:flex;align-items:center;letter-spacing: 0px;",
      //         });
      //     }
      //   }
      // }, 1000);
    },
  },
  watch: {
    Num(newName, oldName) {
      // console.log(newName);
      if (newName === "renderData") {
        // console.log(newName);
        this.data = JSON.parse(localStorage.getItem("shiti"));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.right {
  width: 810px;
  min-height: 800px;
  background-color: #fff;
  padding: 20px 25px 20px 110px;
}
.hover {
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px;
  // margin-top: 10px;
  div:first-child {
    padding: 10px 10px 20px !important;

    // p:first-child {
    //   padding-bottom: 40px !important;
    // }
  }
}
.hover:hover {
  border: 1px solid #d4e0f0;

  .manage {
    visibility: visible;
  }
}

.manage {
  visibility: hidden;
  text-align: right;
  background-color: #4581d4;
  border-radius: 0 0 10px 10px;
  padding-right: 20px;
  span {
    cursor: pointer;
    display: inline-block;
    margin-left: 30px;
    padding: 5px 0;
    outline: none;
    font-size: 14px;
    color: rgb(255, 255, 255);
  }
}
// 拖拽 占位符元素颜色 透明
.ghost {
  opacity: 0 !important;
  background: transparent !important;
}
// 选中的元素
// .chosen {
//   opacity:0!important;
// }
// 拖拽的元素
.drag {
  opacity: 1 !important;
  border: 1px solid #d4e0f0 !important;
  .manage {
    visibility: visible !important;
  }
}
</style>
